import * as React from "react";
import { Interpolation } from "@emotion/core";
import { Device } from "../../design-tokens/media-queries";
import { grid } from "../../design-tokens/grid";

const answerStyle: Interpolation = {
  [Device.Desktop]: {
    padding: `0 ${grid(6)} 0 ${grid(14.5)}`,
  },
  [Device.Tablet]: {
    padding: `0 ${grid(3)}`,
  },
};

const rootStyle: Interpolation = {
  [Device.Desktop]: {
    padding: `0 ${grid(4)}`,
  },
  [Device.Tablet]: {
    padding: `0 ${grid(3)}`,
  },
};

const mobilePadding: Interpolation = {
  [Device.Mobile]: {
    padding: `0 ${grid(2)}`,
  },
};

const styles = { rootStyle, answerStyle, mobilePadding };

interface CardContentProps {
  isNarrow?: boolean;
  isFullWidthOnMobile?: boolean;
}

const CardContent: React.FC<CardContentProps> = ({
  isNarrow,
  isFullWidthOnMobile,
  children,
}) => {
  return (
    <div
      css={[
        isNarrow ? styles.answerStyle : styles.rootStyle,
        !isFullWidthOnMobile && styles.mobilePadding,
      ]}
    >
      {children}
    </div>
  );
};

export default CardContent;
