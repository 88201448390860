import { Interpolation } from "@emotion/core";
import { Fonts } from "./fonts";
import { grid } from "./grid";

const pageStyles: Interpolation = {
  [" p, div, ul, ol"]: {
    fontSize: "18px",
    marginBottom: grid(2),
  },
  ["h2"]: {
    fontSize: "22px",
    fontWeight: Fonts.Weight.Bold,
    marginBottom: grid(2),
  },
};

export default pageStyles;
