import { Interpolation } from "@emotion/core";
import { Fonts } from "../../design-tokens/fonts";
import { Colors } from "../../design-tokens/colors";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { Border } from "../../design-tokens/borders";
import { grid } from "../../design-tokens/grid";
import { Keyframes } from "../../design-tokens/animation";
import { BoxShadow } from "../../design-tokens/box-shadow";

const card: Interpolation = {
  position: "relative",
  backgroundColor: Colors.White,
  padding: `${grid(3)} 0 ${grid(4)}`,
  boxShadow: BoxShadow.Default,
  marginBottom: grid(3),
  animation: `${Keyframes.FadeIn} 0.3s linear`,

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBackground,
  },

  [Device.Desktop]: {
    padding: `${grid(4)} 0`,
    borderRadius: Border.RadiusLg,
  },
};

const badge: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: Fonts.Weight.Bold,
  color: Colors.White,
  backgroundColor: Colors.Blue,

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
  },

  [Device.Mobile]: {
    width: 70,
    height: 20,
    fontSize: "10px",
    borderRadius: "3px",
    marginTop: grid(-1),
    marginLeft: grid(2),
    marginBottom: grid(2),
  },

  [Device.DesktopTablet]: {
    position: "absolute",
    top: 40,
    left: 0,
    width: 100,
    height: 30,
    fontSize: "12px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
  },
};

const styles = {
  card,
  badge,
};

export default styles;
