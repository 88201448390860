import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo/seo";
import Container from "../components/ui-library/container/container";
import PageContainer from "../components/ui-library/page-container/page-container";
import PageHeading from "../components/ui-library/page-heading/page-heading";
import ContentCard from "../components/ui-library/content-card/content-card";
import CardContent from "../components/ui-library/content-card/card-content";
import pageStyles from "../components/design-tokens/common-page-content";
import { LogoBunny } from "../components/site/header/header";
import Navigation from "../components/site/navigation/navigation";

const Disclaimer: React.FC = () => (
  <Layout headerUrl="/" logoBunny={LogoBunny.Main}>
    <SEO
      title="Medical advice disclaimer"
      description="Medical advice disclaimer"
      meta={[{ name: "robots", content: "noindex" }]}
    />

    <Navigation />

    <PageContainer>
      <PageHeading backUrl="/covid-19/">Medical advice disclaimer</PageHeading>

      <Container fullWidthOnMobile={true}>
        <ContentCard>
          <CardContent isNarrow={true}>
            <div css={pageStyles}>
              <h2>Updated March, 2020</h2>
              <p>
                This site is for informational and educational purposes only.
                The information contained herein does not constitute the
                rendering of medical or healthcare advice or the provision of
                treatment or treatment recommendations.
              </p>
              <p>
                Browsing this site does not establish a professional
                relationship with <strong>Ask About</strong> or any member of
                the <strong>Ask About</strong> team. Any medical or healthcare
                related decision should be made in consultation with your
                qualified healthcare provider. This information contained on
                this website is not intended to treat or diagnose.
              </p>
              <h2>Disclaimer of information and content</h2>
              <p>
                The content of <strong>Ask About</strong>‘s website is for
                information only, not advice or guarantee of outcome.
                Information is gathered and shared from reputable sources;
                however, <strong>Ask About</strong> is not responsible for
                errors or omissions in reporting or explanation. No individuals
                should use the information, resources or tools contained within
                to self-diagnosis or self-treat any health-related condition.
                Ask About gives no assurance or warranty regarding the accuracy,
                timeliness or applicability or the content.
              </p>
              <p>
                <strong>Ask About</strong> accepts no liability for errors,
                inaccuracies, omission, or misleading statements.{" "}
                <strong>Ask About</strong> excludes liability for any losses,
                demands, claims or damages of any kind regarding information,
                content, or services at this website. The information may be
                updated at any time, especially as medical discoveries and
                research evolves regarding the topics and subjects discussed and
                presented on our site. At no time does{" "}
                <strong>Ask About</strong> take any responsibility for any
                action taken or care chosen in reliance on information contained
                in this website.
              </p>
              <h2>Disclaimer of endorsement</h2>
              <p>
                Reference within this site to any specific commercial or
                non-commercial product, process or service by trade name or
                trademark, manufacturer or otherwise does not constitute or
                imply an endorsement or recommendation.
              </p>
              <h2>Disclaimer for links to other websites</h2>
              <p>
                Links to other websites are simply for your convenience. Links
                chosen by you to view are at your own risk.{" "}
                <strong>Ask About</strong> takes no liability for any linked
                sites or their content which may change without notice.
              </p>
              <p>
                Any link to an other website does not imply that{" "}
                <strong>Ask About</strong>
                endorses or accepts any responsibility for the content, safety,
                reliability, or quality or the materials. Be sure to study the
                privacy policies and other information about what, how and why a
                website may collect and use information you provide.
              </p>
              <p>
                <strong>
                  Thank you for taking the time to read this disclaimer
                </strong>
              </p>
            </div>
          </CardContent>
        </ContentCard>
      </Container>
    </PageContainer>
  </Layout>
);

export default Disclaimer;
