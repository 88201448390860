import * as React from "react";
import { Interpolation } from "@emotion/core";
import styles from "./content-card.styles";

interface ContentCardProps {
  id?: string;
  featureBadge?: string;
  css?: Interpolation;
}

const ContentCard: React.FC<ContentCardProps> = ({
  id,
  featureBadge,
  children,
  css,
}) => {
  return (
    <div id={id} css={[styles.card, css]}>
      {!!featureBadge && <div css={styles.badge}>{featureBadge}</div>}

      {children}
    </div>
  );
};

export default ContentCard;
